<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" md="8">

          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('technicalOffice')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
                v-for="(d,i) in infoList"
                :key="i"
                dark
                large
            >
              <template v-slot:icon>
                <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                  <img v-if="d.url" :src="d.url">
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
              </template>
              <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
                <v-card-title class="text-h5">
                  <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

                </v-card-title>
                <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

                <v-divider inset style="color: green"></v-divider>

                <v-card-text style="text-align: left">
                  <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                  <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                  <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                  <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                  <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                  <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

export default {
  name: 'AcademicOffice',
  components: {Media,SideBarEvent},
  data(){
    return{
        infoList:[
          {
            name:"ven_sothea",
            position:"chief",
            phoneNumber:"+855-99 868 684",
            bachelor:"english",
            master:"",
            phd:"",
            email:"v.sothea168@yahoo.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2FD2D13843-B532-4A96-A91A-2953572D04B1%20-%20Thea%20Thea-min.jpeg?alt=media&token=ad48520f-d753-4175-b57b-360e5ad94148"
          },{
            name:"huon_phalkun",
            position:"deputyChief",
            phoneNumber:"+855-12 487 574",
            bachelor:"general_management",
            master:"",
            phd:"",
            email:"phalkunhuon@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2FMy%20photo%20-%20Phalkun%20Huon.jpg?alt=media&token=688ba19e-9d81-4418-aeef-595ed28aca98"
          },{
            name:"chhon_reth",
            position:"technicalTeacher",
            phoneNumber:"+855-87 985 282",
            bachelor:"educational_management_science",
            master:"business_administration",
            phd:"",
            email:"chhonreth04@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2FDSC_6822%20copy%20-%20Reth%20Chhon-min.JPG?alt=media&token=fa81f924-ba33-46a5-a046-25075cefb978"
          },{
            name:"moeuk_rithy",
            position:"technicalTeacher",
            phoneNumber:"+855-98 766 712",
            bachelor:"banking_and_finance",
            master:"",
            phd:"",
            email:"moeukrithy69@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2Fphoto_2021-09-01_15-10-23%20-%20Rithy%20Crush.jpg?alt=media&token=5cf3a424-2a61-47ea-8a07-0d8d8fe2d474"
          },{
            name:"sao_sorphorn",
            position:"technicalTeacher",
            phoneNumber:"+855-98 844 478",
            bachelor:"it",
            master:"",
            phd:"",
            email:"sorphornsao83@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2F%E1%9E%9F%E1%9F%85%20%E1%9E%9F%E1%9F%84%E1%9E%97%E1%9F%90%E1%9E%8E%E1%9F%92%E1%9E%8C%20-%20Sorphorn%20Sao.jpg?alt=media&token=2a8cd8a8-55df-410a-9d1d-05046a483086"
          },
          {
            name:"hit_odom",
            position:"technicalTeacher",
            phoneNumber:"+855-69 622 426",
            bachelor:"electronic_engineering",
            master:"",
            phd:"",
            email:"hitodom2015@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ftcdo%2FIMG_20210712_202006_690%20-%20Hit%20Odom.jpg?alt=media&token=18cd9263-8521-4be9-83d4-ee0f5d9ef757"
          },


        ]

    }
  }
}
</script>
